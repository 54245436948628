/* Global Variables */
:root {
  --primary-color: #007bff;
  --secondary-color: #0056b3;
  --font-size-large: 2.8rem;
  --font-size-medium: 1.8rem;
  --font-size-small: 1.6rem;
  --font-size-xsmall: 1rem;
  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-normal: 400;
  --border-radius: 10px;
}

/* Membership Section */
.membership {
  padding: 50px 20px;
  background-color: #f0f4f8;
  text-align: center;
}

.membership h1 {
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: var(--font-size-large);
}

.membership h3 {
  color: #34495e;
  margin-bottom: 10px;
  font-size: var(--font-size-small);
}

.membership p {
  color: #7f8c8d;
  margin-bottom: 15px;
  font-size: var(--font-size-xsmall);
}

.price {
  color: #27ae60;
  font-size: var(--font-size-medium);
  margin-bottom: 20px;
  font-weight: var(--font-weight-bold);
}

.membership-item {
  background-color: #ffffff;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.membership-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.grading {
  margin-bottom: 40px;
}

.btn {
  padding: 12px 25px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
  background-color: var(--secondary-color);
  transform: scale(1.05);
}

.contact-btn {
  margin-top: 20px;
}

/* Responsive Styles */
@media only screen and (min-width: 1200px) {
  .membership {
    padding: 60px 80px;
  }

  .membership h1 {
    font-size: 3.2rem;
  }

  .membership h3 {
    font-size: 2rem;
  }

  .membership p {
    font-size: 1.2rem;
  }

  .price {
    font-size: 2rem;
  }

  .membership-item {
    padding: 40px;
  }

  .btn {
    font-size: 18px;
  }
}
.download-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.download-link:hover {
  background-color: #0056b3;
}


@media only screen and (max-width: 992px) {
  .membership {
    padding: 50px 40px;
  }

  .membership h1 {
    font-size: 2.5rem;
  }

  .membership h3 {
    font-size: 1.6rem;
  }

  .membership p {
    font-size: 1rem;
  }

  .price {
    font-size: 1.8rem;
  }

  .membership-item {
    padding: 35px;
  }

  .btn {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .membership {
    padding: 40px 30px;
  }

  .membership h1 {
    font-size: 2.2rem;
  }

  .membership h3 {
    font-size: 1.4rem;
  }

  .membership p {
    font-size: 0.9rem;
  }

  .price {
    font-size: 1.6rem;
  }

  .membership-item {
    padding: 30px;
  }

  .btn {
    font-size: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .membership {
    padding: 30px 20px;
  }

  .membership h1 {
    font-size: 2rem;
  }

  .membership h3 {
    font-size: 1.3rem;
  }

  .membership p {
    font-size: 0.8rem;
  }

  .price {
    font-size: 1.4rem;
  }

  .membership-item {
    padding: 25px;
  }

  .btn {
    font-size: 14px;
  }
}
