.display-events-container {
  padding: 20px;
}

.event-gallery {
  display: flex;
  flex-wrap: wrap;
}

.event-item {
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.event-image {
  width: 100%;
  height: auto;
}

.event-description {
  padding: 10px;
  text-align: center;
}
