.karate-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.karate-container h1 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 30px;
  color: #222;
  font-family: 'Merriweather', serif;
  text-transform: uppercase;
}

.masters {
  margin-bottom: 40px;
}

.masters h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-align: center;
  font-family: 'Merriweather', serif;
}

.master {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 12px;
  background-color: #fafafa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.master:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.master-image {
  width: 200px;
  height: 380px;
  border-radius: 12px;
  object-fit: cover;
  border: 5px solid #222;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
}
.cmaster-image {
  width: 250px;
  height: 380px;
  border-radius: 12px;
  object-fit: cover;
  border: 5px solid #222;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.master-info p {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #444;
  font-family: 'Open Sans', sans-serif;
}

.master-info strong {
  font-size: 1.5rem;
  color: #111;
  font-family: 'Merriweather', serif;
}

.philosophy, .history, .locations {
  margin-bottom: 40px;
}

.philosophy h2, .history h2, .locations h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-family: 'Merriweather', serif;
  text-transform: uppercase;
}

.philosophy p, .history p {
  font-size: 1.3rem;
  line-height: 1.8;
  color: #555;
  font-family: 'Open Sans', sans-serif;
}

.philosophy blockquote {
  margin: 25px 0;
  padding: 20px;
  background-color: #f1f1f1;
  border-left: 8px solid #007bff;
  font-style: italic;
  color: #333;
  font-family: 'Merriweather', serif;
}

.locations .location {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 12px;
  background-color: #fafafa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.locations .location:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.locations .location a {
  font-size: 1.3rem;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.locations .location a:hover {
  color: #0056b3;
}

.locations .location ul {
  margin: 15px 0 0 30px;
}

.locations .location li {
  font-size: 1.2rem;
  color: #555;
}

@media (max-width: 992px) {
  .master {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .master-image {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .karate-container {
    padding: 20px;
  }

  .karate-container h1 {
    font-size: 2.5rem;
  }

  .masters h2, .philosophy h2, .history h2, .locations h2 {
    font-size: 2rem;
  }

  .master-info p {
    font-size: 1.1rem;
  }

  .philosophy p, .history p, .locations .location li {
    font-size: 1.1rem;
  }

  .locations .location a {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .karate-container h1 {
    font-size: 2rem;
  }

  .masters h2, .philosophy h2, .history h2, .locations h2 {
    font-size: 1.8rem;
  }

  .master-info p, .philosophy p, .history p {
    font-size: 1rem;
  }
}
