:root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --background-color: #f7f7f7;
  --text-color: #333;
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  --item-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: var(--text-color);
}
.logout-button {
  position: absolute;
  top: 75px;
  right: 10px;
  width: auto;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #e60000;
}

.admin-dashboard {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  text-align: center;
}

h1 {
  margin-bottom: 20px;
  color: var(--text-color);
  font-size: 2rem; /* Adjusted for better readability */
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid layout */
  gap: 20px;
  width: 100%;
}

.dashboard-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: var(--item-shadow);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.dashboard-item img {
  width: 80%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

.btn {
  padding: 12px 20px; /* Increased padding for better touch targets */
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
  background-color: var(--primary-hover-color);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .dashboard-item {
    padding: 15px; /* Reduced padding for smaller screens */
  }

  .dashboard-item img {
    width: 90%; /* Adjusted image width for better fit on smaller screens */
  }
}
