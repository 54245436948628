/* RemoveMedia.css */

.remove-media-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .remove-media-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 28px;
  }
  
  .media-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
    grid-gap: 20px;
  }
  
  .media-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .media-item img,
  .media-item video {
    width: 100%; /* Ensure the media fills its container */
    height: 100%; /* Ensure the media fills its container */
    object-fit: cover; /* Ensure the media covers the entire area */
    display: block;
    border-radius: 8px;
  }
  
  .remove-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: red; /* Red color */
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .remove-button:focus,
  .remove-button:hover {
    background-color: #c82333; /* Darker red on hover */
    outline: none;
  }
  
  .media-item:hover .remove-button {
    transform: translate(-50%, -50%) scale(1.1); /* Scale up on hover */
  }
  
  @media (max-width: 600px) {
    .media-list {
      grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
    }
  }
  
  @media (max-width: 400px) {
    .media-list {
      grid-template-columns: 1fr; /* Single column on very small screens */
    }
  }
  