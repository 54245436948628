/* Services Section */
.services-section {
    padding: 80px 0;
    background-color:none;
  }
  
  .subtitle {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  .service-title {
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 50px;
    color: #007bff;
    text-align: center;
  }
  
  .resource-buttons {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-link {
    display: block;
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    margin: 0 20px 20px 0;
    padding: 10px 20px;
    border: 2px solid #007bff;
    border-radius: 5px;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .service-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .service-item {
    margin-bottom: 40px;
  }
  
  .service-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
  
  .service-content {
    padding: 30px;
  }
  
  .service-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .service-description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  @media only screen and (max-width: 1200px) {
    .resource-buttons {
      justify-content: flex-start;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .service-link {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .service-title {
      font-size: 2.5rem;
    }
  
    .service-heading {
      font-size: 1.8rem;
    }
  
    .service-description {
      font-size: 1rem;
    }
  }
  