/* ShotokanKarate.css */

.shotokan-karate-section {
    padding: 20px;
    
    border-radius: 8px;
    background-color: none;
    margin-bottom: 20px;
  }
  
  .shotokan-karate-section h2 {
    color: black;
    font-weight: 500;
  }
  
  .shotokan-karate-section h3 {
    color: black;
    margin-top: 20px;
  }
  
  .shotokan-karate-section p,
  .shotokan-karate-section ul {
    margin: 10px 0;
    color:black;
    font-weight: 500;
  }
  
  .shotokan-karate-section ul {
    padding-left: 20px;
  }
  
  .shotokan-karate-section ul li {
    margin-bottom: 10px;
  }
  