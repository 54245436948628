/* Testimonials Slider */
.testimonials-slider {
  margin: 50px 0;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
}

/* Testimonial Card */
.testimonial {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial:hover {
  transform: translateY(-5px); /* Lift card on hover */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.testimonial p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555555;
  margin-bottom: 20px;
}

/* Testimonial Info */
.testimonial-info {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.author {
  flex: 1;
}

.author h6 {
  font-size: 1.2rem;
  margin: 0;
  color: #333333;
}

.author span {
  font-size: 0.9rem;
  color: #666666;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .testimonial {
    padding: 15px; /* Adjusted padding for smaller screens */
  }

  .testimonial p {
    font-size: 1rem; /* Reduced font size for smaller screens */
  }

  .avatar {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 576px) {
  .testimonial {
    padding: 12px; /* Further adjusted padding for smaller screens */
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .section-title {
    font-size: 2rem; /* Adjusted font size for smaller screens */
    margin-bottom: 30px; /* Reduced margin bottom for smaller screens */
  }

  .author h6 {
    font-size: 1rem; /* Reduced font size for smaller screens */
  }

  .author span {
    font-size: 0.8rem; /* Reduced font size for smaller screens */
  }
}
