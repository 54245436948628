:root {
  --primary-color: #007bff;
  --secondary-color: #0056b3;
  --text-color: #343a40;
  --heading-color: #212529;
  --background-color: #f8f9fa;
  --accent-color: #ff6f61; /* For accents like hover effects */
}

.footer {
  padding: 70px 0;
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
}

.footer-logo img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin-bottom: 2rem;
  object-fit: cover;
  object-position: center;
  border: 4px solid var(--primary-color);
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.footer-logo img:hover {
  transform: scale(1.2); /* Slight zoom on hover */
}

.footer-quote {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--heading-color);
  font-style: italic;
  font-weight: 500; /* Slightly bolder */
  margin-bottom: 1.8rem;
}

.social-links {
  margin-top: 1.8rem;
}

.social-links a {
  display: inline-block;
  margin-right: 20px;
  color: var(--heading-color);
  font-size: 1.8rem;
  transition: color 0.3s, transform 0.3s;
}

.social-links a:hover {
  color: var(--accent-color);
  transform: translateY(-5px); /* Added animation for hover */
}

.footer-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--heading-color);
  margin-bottom: 1.8rem;
  text-transform: uppercase;
}

.footer-links,
.footer-contact,
.footer-locations {
  list-style: none;
  padding: 0;
  margin-bottom: 1.8rem;
}

.footer-links li,
.footer-contact li,
.footer-locations li {
  margin-bottom: 1rem; /* Increased spacing for cleaner layout */
}

.footer-links a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 1.1rem;
  font-weight: 600;
  transition: color 0.3s, font-weight 0.3s;
}

.footer-links a:hover {
  color: var(--accent-color);
  font-weight: 700; /* Bolder on hover */
}

.footer-contact li {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.footer-contact i {
  font-size: 1.3rem;
  color: var(--primary-color);
  margin-right: 12px;
}

.footer-locations li a {
  font-weight: 600;
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s, transform 0.3s;
}

.footer-locations li a:hover {
  color: var(--accent-color);
  transform: translateX(5px); /* Subtle slide effect on hover */
}

.footer-locations li p {
  font-size: 1rem;
  color: var(--text-color);
  margin-top: 0.5rem;
}

.footer-locations li p span {
  font-weight: 600;
  color: var(--heading-color); /* Darker for days like Monday, Tuesday */
}

.footer-text,
.footer-designer-info {
  font-size: 1rem;
  color: var(--text-color);
  margin-top: 2rem;
  line-height: 1.7;
  font-weight: 500; /* Bolder for readability */
}

.footer-designer-info a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s;
}

.footer-designer-info a:hover {
  color: var(--accent-color);
}

/* Responsive Design */
@media (min-width: 768px) {
  .footer {
    text-align: left;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-logo,
  .footer-quote,
  .footer-links,
  .footer-contact {
    flex: 1;
    margin-right: 2rem;
  }

  .footer-logo,
  .footer-quote,
  .footer-contact {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .footer-logo img {
    width: 160px;
    height: 160px;
  }

  .footer-title {
    font-size: 1.5rem;
  }

  .footer-links a,
  .footer-contact li {
    font-size: 1.2rem;
  }

  .footer-text,
  .footer-designer-info {
    font-size: 1.1rem;
  }
}
