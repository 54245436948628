/* Global Variables */
:root {
  --primary-color: #007bff;
  --secondary-color: #0056b3;
  --font-size-large: 2.5rem;
  --font-size-medium: 2rem;
  --font-size-small: 1.5rem;
  --font-size-xsmall: 1rem;
  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-normal: 400;
  --border-radius: 20px;
}

/* Reset and Basic Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

/* Hero Section */
.hero {
  background-size: cover;
  background-position: center;
  margin-bottom: 2rem;
  padding-top: 3rem; /* Adjusted padding to reduce the gap */
  color: black;
  text-align: center;
}

.hero-content {
  padding-top: 1.5rem; /* Adjusted padding to reduce the gap */
}

.hero-content h1 {
  font-size: 3.2rem;
  font-weight: var(--font-weight-bold);
  margin: 1rem 0 2rem 0;
  color: black;
}

.hero-content p {
  font-size: 1.1rem;
  line-height: 2rem;
  font-weight: bold;
  color: black;
}

.subtitle {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  color: var(--secondary-color);
}

.hero-img-box {
  width: 100%;
  height: 5;
  border-radius: var(--border-radius);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hero-img-box img,
.hero-img-box video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transition: transform 0.3s ease;
}

.hero-img-box:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.hero-img-box.logo {
  margin: 20px 0;
  overflow: hidden;
  border-radius: 50%; /* Makes the container circular */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 200px; /* Set a fixed width to maintain the circular shape */
  height: 200px; /* Set a fixed height to maintain the circular shape */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-img-box.logo img {
  object-fit: cover; /* Ensures the logo is contained within the box */
  height: 300%; /* Use 100% to fill the circular container */
  width: 300%; /* Use 100% to fill the circular container */
  border-radius: 50%; /* Makes the image circular */
  transition: transform 0.3s ease;
  transform: translateX(1%);
}

.hero-img-box.master img {
  object-fit: cover; /* Ensures the master image covers the entire box */
  width: 350%;
}

.hero-video-box {
  margin-top: 1rem;
}

/* Introduction Section */
.section-gap {
  margin-top: -60px; /* Adjust as needed to reduce the gap */
}

/* Service Section */
.services-section {
  padding: 80px 0;
  background-color: none;
}

.service-title {
  font-size: 3rem;
  margin-top: 20px;
  margin-bottom: 50px;
  color: #007bff;
  text-align: center;
}

.resource-buttons {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-link {
  display: block;
  text-decoration: none;
  color: #007bff;
  font-size: 1.2rem;
  margin: 0 20px 20px 0;
  padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 5px;
  text-align: center;
  transition: all 0.3s ease;
}

.service-link:hover {
  background-color: #007bff;
  color: #fff;
}

.service-item {
  margin-bottom: 40px;
}

.service-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.service-content {
  padding: 30px;
}

.service-heading {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.service-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 30px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (max-width: 992px) {
  .hero-content h1 {
    font-size: 2.7rem;
  }

  .hero-img-box {
    display: none; /* Hide image box on medium screens */
  }

  .hero-video-box {
    display: block; /* Show video box on medium screens */
  }

  .service-title,
  .testimonial-title {
    font-size: var(--font-size-medium);
  }

  .hero-img-box.master img {
    width: 100%; /* Ensure the master image fits the container */
    height: auto;
    object-fit: contain; /* Maintain aspect ratio */
  }

  .hero-img-box.master {
    width: 100%; /* Container takes full width */
    height: auto; /* Adjust height automatically */
  }
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (max-width: 576px) {
  .hero-content h1 {
    font-size: 1.8rem;
  }

  .service-title,
  .testimonial-title {
    font-size: var(--font-size-small);
  }

  .service-link {
    font-size: var(--font-size-xsmall);
    padding: 15px;
  }

  .hero-content {
    padding-top: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .service-img-box img {
    width: 100%;
    height: auto;
  }

  .hero-img-box.master img {
    width: 100%; /* Make the image fill the width of the container */
    height: auto; /* Adjust height to maintain the aspect ratio */
    object-fit: contain; /* Ensure the entire image is visible */
  }

  .hero-img-box.master {
    width: 100%; /* Make sure the container itself is full width */
    height: auto; /* Adjust height to fit the image */
    margin-bottom: 1rem; /* Add some space below the image */
  }
}
