.grades-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px;
}

.grade {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    width: 280px; /* Adjusted width for better responsiveness */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grade:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.grade h2 {
    color: #333;
    font-size: 1.8rem; /* Responsive font size */
    margin-bottom: 10px;
}

.grade p {
    color: #555;
    font-size: 1rem; /* Responsive font size */
    line-height: 1.6;
}

.kata {
    margin-top: 15px;
}

.kata h4 {
    color: #333;
    font-size: 1.5rem; /* Responsive font size */
    margin-bottom: 5px;
}

.kata p {
    color: #555;
    font-size: 1rem; /* Responsive font size */
    line-height: 1.5;
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .grade {
        width: 90%; /* Adjusted for tablets */
    }
}

/* Small devices (phones, 480px and up) */
@media (max-width: 480px) {
    .grade {
        width: 100%; /* Full width on small screens */
    }
}
