/* Overall body style */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f8; /* Light background for a clean look */
}

/* Media gallery title */
h1 {
  text-align: center;
  margin: 20px 0;
  color: #343a40; /* Dark color for contrast */
  font-size: 2.5em; /* Larger title for emphasis */
}

/* Gallery container */
.gallery-container {
  max-width: 600px; /* Max width for the gallery */
  margin: 0 auto; /* Center the gallery */
  padding: 20px; /* Padding around the container */
}

/* Gallery styling */
.gallery {
  display: flex;
  flex-direction: column; /* Stack items in a column */
  gap: 20px; /* Space between items */
}

/* Individual gallery item */
.gallery-item {
  position: relative; /* For positioning overlay */
  background: white; /* White background for items */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Hide overflow for rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition */
  cursor: pointer; /* Pointer cursor for clickable items */
  outline: none;
}

/* Scale effect on hover */
.gallery-item:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Image styling */
.gallery-item img {
  width: 100%; /* Make image full width */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove space below images */
}

/* Video styling */
.gallery-item video {
  width: 100%; /* Make video full width */
  height: auto; /* Maintain aspect ratio */
}

/* Fullscreen media styles */
.fullscreen-media {
  position: fixed; /* Fixed position for fullscreen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85); /* Dark semi-transparent background */
  display: flex; /* Center content */
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's on top */
}

/* Fullscreen image and video styling */
.fullscreen-media img,
.fullscreen-media video {
  max-width: 90%; /* Max width for fullscreen media */
  max-height: 90%; /* Max height for fullscreen media */
  border-radius: 10px; /* Rounded corners */
}

/* Error message styling */
p {
  text-align: center;
  color: red; /* Red color for error messages */
}

/* Responsive styling */
@media (max-width: 768px) {
  h1 {
      font-size: 2em; /* Adjust title size on smaller screens */
  }

  .gallery-container {
      padding: 10px; /* Less padding on smaller screens */
  }
}

@media (max-width: 480px) {
  h1 {
      font-size: 1.5em; /* Smaller title on very small screens */
  }

  .gallery-container {
      padding: 5px; /* Minimal padding on very small screens */
  }
}
