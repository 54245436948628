/* StudentProfile.css */

.student-profile-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background: linear-gradient(to bottom, #e0eafc, #cfdef3);
  padding: 40px 20px;
}

.student-details-card {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 700px;
  width: 100%;
  text-align: center;
  border: 1px solid #dcdcdc;
  margin-top: 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.student-details-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: #e74c3c;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  background: rgba(231, 76, 60, 0.1);
  border: 1px solid #e74c3c;
  padding: 10px;
  border-radius: 8px;
}

.student-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px; /* Space between student info and attendance form */
}

.student-info h2 {
  font-size: 30px;
  color: #2c3e50;
  margin-bottom: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.student-info p {
  font-size: 20px;
  color: #34495e;
  margin: 10px 0;
  font-family: 'Arial', sans-serif;
}

.student-info p strong {
  color: #2c3e50;
  font-weight: 600;
}

.attendance-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px; /* Space between form and attendance list */
}

.attendance-form label {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.attendance-form input,
.attendance-form select {
  font-size: 16px;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.attendance-form button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.attendance-form button:hover {
  background-color: #2980b9;
}

.attendance-list {
  margin-top: 20px;
}

.attendance-list h3 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.attendance-list table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-list th,
.attendance-list td {
  border: 1px solid #dcdcdc;
  padding: 12px;
  text-align: left;
}

.attendance-list th {
  background-color: #3498db;
  color: #fff;
}

.attendance-list td {
  background-color: #f9f9f9;
}

.attendance-list tr:nth-child(even) td {
  background-color: #f1f1f1;
}

.attendance-list tr:hover td {
  background-color: #e0eafc;
}
