.add-media-container {
  padding: 20px;
  max-width: 600px;
  margin: 40px auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.add-media-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.add-media-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-media-container input[type="file"] {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

.add-media-container button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-media-container button:hover {
  background-color: #45a049;
}

.add-media-container p {
  text-align: center;
  margin-top: 20px;
  color: #333;
  font-size: 18px;
}
