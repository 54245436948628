/* RajeshSirDetails.css */

.master-details-section1 {
    padding: 60px 20px;
    background: linear-gradient(135deg, #f9f9f9, #e2e2e2);
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    color: #333;
    font-family: 'Arial', sans-serif;
}

.master-image-container {
    text-align: center;
    margin-bottom: 30px;
}

.master-image12 {
    width: 100%;
    max-width: 600px; /* Adjusted size */
    height: auto;
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.master-image12:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.35);
}

.master-info-container {
    color: #2c3e50;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.master-name1 {
    font-size: 2.8rem;
    font-weight: 700;
    color: #e74c3c;
    margin-bottom: 20px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.master-description1 {
    font-size: 1.1rem; /* Slightly smaller */
    line-height: 1.8;
    color: #34495e;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
}

.media-gallery1 {
    margin-top: 40px;
}

.gallery-item {
    margin-bottom: 30px;
    padding: 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image1,
.gallery-video {
    width: 100%;
    height: auto;
    border-radius: 20px;
    transition: transform 0.3s ease, filter 0.3s ease;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.gallery-item:hover .gallery-image1,
.gallery-item:hover .gallery-video {
    transform: scale(1.05);
    filter: brightness(0.85);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.gallery-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(231, 76, 60, 0.5), rgba(192, 57, 43, 0.5));
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 20px;
}

.gallery-item:hover:before {
    opacity: 1;
}

@media (max-width: 768px) {
    .master-name1 {
        font-size: 2.5rem;
    }

    .master-description1 {
        font-size: 1rem;
    }

    .gallery-item {
        padding: 0;
    }
}

@media (max-width: 576px) {
    .master-image12 {
        max-width: 300px;
    }

    .master-name1 {
        font-size: 2rem;
    }

    .master-description1 {
        font-size: 0.9rem;
    }
}
