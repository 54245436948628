:root {
  --primary-color: #007bff;
  --secondary-color: #0056b3;
  --font-size-large: 2.5rem;
  --font-size-medium: 2rem;
  --font-size-small: 1.5rem;
  --font-size-xsmall: 1rem;
  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-normal: 400;
  --border-radius: 20px;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background: #fff;
  box-shadow: 3px 3px 8px -3px #eee;
  position: sticky;
  top: 0;
  z-index: 999;
}

.sticky_header {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.club_name {
  font-weight: bold;
  color: #000;
  text-decoration: none;
  font-size: var(--font-size-medium);
}

.navigation {
  display: none;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav_item {
  margin: 1rem 0;
}

.nav_item a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease;
}

.nav_item a:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.nav_item a.active_link {
  color: var(--secondary-color);
}

.nav_right {
  display: flex;
  align-items: center;
}

.mobile_menu {
  font-size: 2rem;
  color: var(--primary-color);
  display: block;
  cursor: pointer;
}

.show_menu {
  display: flex !important;
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background: #fff;
  flex-direction: column;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 998;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.show_menu .menu {
  flex-direction: column;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 992px) {
  .club_name {
    font-size: var(--font-size-small);
  }

  .navigation {
    display: none;
  }

  .mobile_menu {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .logo img {
    width: 40px;
  }

  .club_name {
    font-size: var(--font-size-xsmall);
  }

  .nav_item a {
    font-size: var(--font-size-xsmall);
  }
}

@media only screen and (max-width: 576px) {
  .club_name {
    font-size: var(--font-size-xsmall);
  }

  .nav_item a {
    font-size: var(--font-size-xsmall);
    padding: 0.5rem;
  }
}
