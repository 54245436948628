/* Global Variables */
:root {
  --primary-color: #007bff;
  --secondary-color: #0056b3;
  --font-size-large: 2.5rem;
  --font-size-medium: 2rem;
  --font-size-small: 1.5rem;
  --font-size-xsmall: 1rem;
  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-normal: 400;
  --border-radius: 10px;
}

/* Programs Section */
.programs {
  padding: 40px 20px;
  background-color: #f8f9fa;
  text-align: center;
}

h1 {
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: var(--font-size-large);
}

h3 {
  color: #34495e;
  margin-bottom: 15px;
  font-size: var(--font-size-medium);
}

p {
  color: #000000;
  margin-bottom: 20px;
  font-size: var(--font-size-small);
}

.program-item {
  background-color: #ffffff;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
}

.program-item:hover {
  transform: scale(1.05);
}

/* Responsive Styles */
@media only screen and (min-width: 1200px) {
  .programs {
    padding: 60px 80px;
  }

  h1 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.2rem;
  }

  p {
    font-size: 1.2rem;
  }

  .program-item {
    padding: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .programs {
    padding: 50px 40px;
  }

  h1 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }

  .program-item {
    padding: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .programs {
    padding: 40px 30px;
  }

  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .program-item {
    padding: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .programs {
    padding: 30px 20px;
  }

  h1 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  p {
    font-size: 0.8rem;
  }

  .program-item {
    padding: 15px;
  }
}
