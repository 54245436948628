:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --form-background: #ffffff;
    --border-color: #ddd;
    --box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    --input-border-color: #ccc;
    --input-focus-border: #1535eb;
    --input-padding: 10px;
    --button-padding: 10px;
    --error-color: red;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: var(--background-color);
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background-color);
}

.form-selection {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.login-form {
    width: 300px;
    padding: 20px;
    background-color: var(--form-background);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.login-form:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.login-form h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

input[type="email"],
input[type="password"],
input[type="text"] {
    width: 100%;
    padding: var(--input-padding);
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus {
    border-color: var(--input-focus-border);
    outline: none;
}

button {
    width: 100%;
    padding: var(--button-padding);
    background-color: var(--primary-color);
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size:1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

button:hover {
    background-color: var(--primary-hover-color);
    transform: translateY(-2px);
}

.error {
    color: var(--error-color);
    font-size: 0.875rem;
    margin-top: 5px;
}

@media (max-width: 768px) {
    .login-form {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .login-form {
        width: 90%;
    }

    h2 {
        font-size: 1.2rem;
    }

    .form-group {
        margin-bottom: 10px;
    }

    input[type="email"],
    input[type="password"],
    input[type="text"] {
        padding: 8px;
    }

    button {
        padding: 8px;
        font-size: 0.875rem;
    }
}

@media (max-width: 360px) {
    .login-form {
        width: 95%;
    }

    h2 {
        font-size: 1rem;
    }

    .form-group {
        margin-bottom: 8px;
    }

    input[type="email"],
    input[type="password"],
    input[type="text"] {
        padding: 6px;
    }

    button {
        padding: 6px;
        font-size: 0.75rem;
    }
}
