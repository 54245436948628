.attendance-report {
  padding: 20px;
}

.filters {
  margin-bottom: 20px;
}

.filters label {
  margin-right: 10px;
}

.filters button {
  margin-left: 10px;
}

.error {
  color: red;
}

.attendance-table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-table th, .attendance-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.attendance-table th {
  background-color: #f4f4f4;
}
