.attendance {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .date-picker-container {
    margin-bottom: 20px;
  }
  
  .date-picker-label {
    font-size: 16px;
    margin-right: 10px;
  }
  
  .date-picker {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .student-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .student-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .student-item:last-child {
    border-bottom: none;
  }
  
  .student-name {
    font-size: 16px;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .checkbox-label {
    margin-right: 20px;
    font-size: 14px;
  }
  
  .btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #45a049;
  }
  