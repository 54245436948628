/* PradeepSirDetails.css */

.master-details-section {
    padding: 50px 20px;
    background: linear-gradient(135deg, #f0f4f8, #d9e2ec);
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.master-image-container {
    text-align: center;
    margin-bottom: 30px;
}

.master-image1 {
    height: 600px;
    width: 100%;
    
    max-width: 350px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.master-image1:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.master-info-container {
    color: #2c3e50;
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.master-name {
    font-size: 2.5rem;
    font-weight: 700;
    color: #e74c3c;
    margin-bottom: 20px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.master-description {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #34495e;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
}

.media-gallery {
    margin-top: 40px;
}

.gallery-item {
    margin-bottom: 30px;
    padding: 0 15px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
    transition: transform 0.3s ease, filter 0.3s ease;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.gallery-item:hover .gallery-image {
    transform: scale(1.05);
    filter: brightness(0.85);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.gallery-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(231, 76, 60, 0.5), rgba(192, 57, 43, 0.5));
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 15px;
}

.gallery-item:hover:before {
    opacity: 1;
}

@media (max-width: 768px) {
    .master-name {
        font-size: 2.2rem;
    }

    .master-description {
        font-size: 1.1rem;
    }

    .gallery-item {
        padding: 0;
    }
}

@media (max-width: 576px) {
    .master-image1 {
        max-width: 250px;
    }

    .master-name {
        font-size: 1.8rem;
    }

    .master-description {
        font-size: 1rem;
    }
}
