.student-form {
  max-width: 1000px;
  margin: auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  position: relative;
  background-color: #f9f9f9;
}

.student-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.form-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-column {
  flex: 1;
  min-width: 45%;
  margin-right: 20px;
}

.form-column:last-child {
  margin-right: 0;
}

.student-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.student-form input,
.student-form textarea {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.signature-section, .for-office-use-section {
  margin-top: 30px;
  padding: 15px;
  border-top: 1px solid #ddd;
  background-color: #fff;
}

.signature-section h3,
.for-office-use-section h3 {
  font-size: 20px;
  color: #555;
}

.signature-canvas {
  border: 1px solid #ddd;
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.form-buttons {
  text-align: center;
  margin-top: 30px;
}

.student-form button,
.download-btn {
  padding: 12px 25px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.student-form button {
  background-color: #007bff;
  color: #fff;
}

.student-form button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.download-btn {
  background-color: #28a745;
  color: #fff;
}

.download-btn:hover {
  background-color: #218838;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.student-image {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 3px solid #007bff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.webcam-container {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  z-index: 10;
  background-color: #fff;
  border: 2px solid #007bff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.webcam-container button {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.webcam-container button:hover {
  background-color: #0056b3;
}
